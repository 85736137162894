<template>
  <div class="detailThree">
    <div class="header">
      <span>售后保障</span>
    </div>
    <div class="center">
      <div>
        <p><i class="iconfont">&#xe648;</i><span>卖家服务</span></p>
        <p>
          <span> </span>
        </p>
      </div>
      <div>
        <p><i class="iconfont">&#xe648;</i><span>厨盟承诺</span></p>
        <p>
          <span>
            厨盟平台卖家销售并发货的商品，由平台卖家提供发票和相应的售后服务。请您放心购买！<br />
            注：因厂家会在没有任何提前通知的情况下更改产品包装、产地或者一些附件，本司不能确保客户收到的货物与商城图片、产地、附件说明完全一致。只能确保为原厂正货！并且保证与当时市场上同样主流新品一致。若本商城没有及时更新，请大家谅解！
          </span>
        </p>
      </div>
      <div>
        <p><i class="iconfont">&#xe648;</i><span>正品行货</span></p>
        <p>
          <span>
            厨盟商城向您保证所售商品均为正品行货，厨盟商品开具机打发票或电子发票。
          </span>
        </p>
      </div>
    </div>
    <div class="bottom">
      <div>
        <p class="title">权利声明：</p>
        <p>
          <span
            >厨盟上的所有商品信息、客户评价、商品咨询、网友讨论等内容，是厨盟重要的经营资源，未经许可，禁止非法转载使用。</span
          >
        </p>
        <p>
          <span class="lable">注：</span>
          <span
            >本站商品信息均来自于合作方，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。
          </span>
        </p>
      </div>
      <div>
        <p class="title">价格说明：</p>
        <p>
          <span class="lable">厨盟价：</span>
          <span>厨盟价为商品的销售价，是您最终决定是否购买商品的依据。</span>
        </p>
        <p>
          <span class="lable">划线价：</span>
          <span
            >商品展示的划横线价格为参考价，并非原价，该价格可能是品牌专柜标价、商品吊牌价或由品牌供应商提供的正品零售价（如厂商指导价、建议零售价等）或该商品在厨盟平台上曾经展示过的销售价；由于地区、时间的差异性和市场行情波动，品牌专柜标价、商品吊牌价等可能会与您购物时展示的不一致，该价格仅供您参考。</span
          >
        </p>
        <p>
          <span class="lable"> 折扣： </span>
          <span
            >如无特殊说明，折扣指销售商在原价、或划线价（如品牌专柜标价、商品吊牌价、厂商指导价、厂商建议零售价）等某一价格基础上计算出的优惠比例或优惠金额；如有疑问，您可在购买前联系销售商进行咨询。</span
          >
        </p>
        <p>
          <span class="lable">异常问题：</span>
          <span
            >商品促销信息以商品详情页“促销”栏中的信息为准；商品的具体售价以订单结算页价格为准；如您发现活动商品售价或促销信息有异常，建议购买前先联系销售商咨询。</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["messageList"],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
* {
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
    "\u5b8b\u4f53", sans-serif;
  color: #666;
}
.detailThree {
  .header {
    padding: 10px;
    background-color: #f7f7f7;
    border: 1px solid #eee;
    margin: 20px 0px;
    span {
      font: 700 14px "microsoft yahei";
    }
  }
  .center {
    div {
      margin-bottom: 33px;
      & > p:nth-child(1) {
        height: 40px;
        margin-left: 35px;
        i {
          font-size: 30px;
          color: #e50100;
        }
        span {
          position: relative;
          top: -6px;
          padding-left: 10px;
          font-size: 16px;
          color: #e4393c;
          font-family: "microsoft yahei";
          font-weight: bold;
        }
      }
      & > p:nth-child(2) {
        margin-left: 70px;
      }
    }
  }
  .bottom {
    padding: 10px;
    div {
      .title {
        color: #e4393c;
        padding: 8px 0 3px;
        font-weight: bold;
        line-height: 180%;
      }
      p {
        .lable {
          font-weight: bold;
          line-height: 180%;
        }
      }
    }
  }
}
</style>