<template>
  <div class="detail">
    <div class="header">
      <div>
        <span :class="activeIndex == 0 ? 'active' : ''" @click="activeIndex = 0"
          >商品介绍</span
        >
        <span :class="activeIndex == 1 ? 'active' : ''" @click="activeIndex = 1"
          >规格和包装</span
        >
        <span :class="activeIndex == 2 ? 'active' : ''" @click="activeIndex = 2"
          >售后保障</span
        >
        <span :class="activeIndex == 3 ? 'active' : ''" @click="activeIndex = 3"
          >商品评价</span
        >
      </div>
      <div>
        <span @click="$emit('addShop')">加入购物车</span>
      </div>
    </div>
    <div class="center">
      <detail-one
        :messageList="messageList"
        v-if="activeIndex != 1 && activeIndex != 2 && activeIndex != 3"
      ></detail-one>
      <detail-two
        :messageList="messageList"
        v-if="activeIndex == 1"
      ></detail-two>
      <detail-three
        :messageList="messageList"
        v-if="activeIndex != 3"
      ></detail-three>
      <detail-four :messageList="messageList"  ></detail-four>
    </div>
  </div>
</template>

<script>
import detailOne from "../datails/detailOne";
import detailTwo from "../datails/datailTwo";
import detailThree from "../datails/datailThree";
import detailFour from "../datails/datailFour";
export default {
  components: { detailOne, detailTwo, detailThree, detailFour },
  props: ["messageList"],
  data() {
    return {
      // 当前选中模块
      activeIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
    "\u5b8b\u4f53", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #666;
}
// 选中样式
.active {
  background-color: #e4393c;
  color: #fff !important;
  cursor: default;
}
.detail {
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
    border: 1px solid #eee;
    border-bottom: 1px solid #e4393c;
    & > div:nth-child(1) {
      span {
        display: inline-block;
        padding: 10px 25px;
        font-size: 14px;
        font-family: "microsoft yahei";
        cursor: pointer;
        &:hover {
          color: #e4393c;
        }
      }
    }
    & > div:nth-child(2) {
      span {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        background-color: #df3033;
        color: #fff;
        height: 28px;
        line-height: 28px;
        padding: 0 16px;
        margin-top: 8px;
        margin-right: 10px;
      }
    }
  }
}
</style>