<template>
  <div class="box">
    <div class="detail-left">
      <div>
        <span>{{ item.userName }}</span>
      </div>
    </div>
    <div class="detail-right">
      <el-rate
        :value="parseFloat(item.star)"
        :allow-half="true"
        disabled
        :colors="['#e4393c', '#e4393c', '#e4393c']"
        score-template="{value}"
      >
      </el-rate>
      <p class="matter">{{ item.matter }}</p>
      <!-- 评论图片小图 -->
      <div class="imgUrl" v-if="JSON.parse(item.imgUrl).length">
        <div
          v-for="(v, k) of JSON.parse(item.imgUrl)"
          :key="k"
          :style="{ border: '2px solid #e53e41;' }"
          @click="activeImg = k"
        >
          <img :src="v.img" alt="" />
        </div>
      </div>
      <!-- 评论图片大图 -->
      <div class="ActiveimgUrl" v-if="JSON.parse(item.imgUrl).length">
        <div>
          <img :src="JSON.parse(item.imgUrl)[activeImg].img" alt="" />
        </div>
      </div>
      <!-- 底部 -->
      <div class="bottom">
        <div class="bottom-left">
          <span v-if="item.productParam">{{ item.productParam.size }}</span>
          <span>{{ item.createTime }}</span>
        </div>
        <div class="bottom-right">
          <span @click="goEvaluateDetail">
            <i class="el-icon-chat-dot-round"></i>
            <span>{{ item.returnNum }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      // 当前选中评论图片
      activeImg: 0,
      cloneItem: {},
    };
  },
  created() {
    // this.cloneItem=this.item
    // this.item.imgUrl=JSON.parse(this.item.imgUrl)
  },
  methods: {
    // 去往评价详情页
    goEvaluateDetail() {
      this.$router.push({
        path: "/datailFourEvaluateDetail",
        query: {
          item: JSON.stringify(this.item),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  padding: 10px;

  .detail-left {
    & > div:nth-child(1) {
      padding-left: 35px;
      line-height: 45px;
    }
    box-sizing: border-box;
    flex: 2;
  }
  .detail-right {
    flex: 10;
    padding: 10px;

    .matter {
      font-size: 14px;
      padding: 8px 4px;
      line-height: 180%;
      color: #333;
    }
    .imgUrl {
      display: flex;
      & > div {
        border: 1px solid #e2e2e2;
        padding: 1px;
        margin-right: 6px;
        img {
          vertical-align: middle;
          width: 48px;
          height: 48px;
          cursor: pointer;
        }
      }
    }
    .ActiveimgUrl {
      padding-top: 0px;
      padding-bottom: 0px;
      width: auto;
      height: auto;
      position: relative;

      & > div {
        img {
          margin: 7px 0 11px;
          border: 1px solid #e3e3e3;
          transform: rotate(0deg);
          max-width: 370px;
          max-height: 478px;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      .bottom-left {
        span {
          margin-left: 25px;
        }
      }
      .bottom-right {
        margin-right: 10px;
        span {
          cursor: pointer;
          i {
            font-size: 18px;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>