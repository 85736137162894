<template>
  <div class="detailFour">
    <div class="header">
      <span>商品评价</span>
    </div>
    <div class="tabbar">
      <div>
        <span
          :style="{ color: active == 0 ? '#e4393c' : '#666' }"
          @click="active = 0"
          >全部评价</span
        >
        <span
          :style="{ color: active == 1 ? '#e4393c' : '#666' }"
          @click="active = 1"
          >晒图</span
        >
        <span
          :style="{ color: active == 2 ? '#e4393c' : '#666' }"
          @click="active = 2"
          >追评</span
        >
        <span
          :style="{ color: active == 3 ? '#e4393c' : '#666' }"
          @click="active = 3"
          >好评</span
        >
        <span
          :style="{ color: active == 4 ? '#e4393c' : '#666' }"
          @click="active = 4"
          >中评</span
        >
        <span
          :style="{ color: active == 5 ? '#e4393c' : '#666' }"
          @click="active = 5"
          >差评</span
        >
        <span>
          <el-checkbox v-model="checked" @change="getEvaluate()"></el-checkbox>
          <span style="padding: 3px">只看当前规格评价</span>
        </span>
      </div>
      <div @mouseleave="orderbyShow = false" @mouseenter="orderbyShow = true">
        <div v-if="!orderbyShow">
          <span>
            推荐排序
            <i class="el-icon-arrow-down"></i>
          </span>
        </div>
        <!-- 推荐排序列表 -->
        <div v-if="orderbyShow" class="orderby">
          <span>
            推荐排序
            <i class="el-icon-arrow-up"></i>
          </span>
          <span> 推荐排序 </span>
          <span> 时间排序 </span>
        </div>
      </div>
    </div>
    <div class="detail" v-for="(v, k) of EvaluateList" :key="k">
      <evaluate :item="v"></evaluate>
    </div>
    <!-- 分页 -->
    <el-pagination
      :style="{
        textAlign: 'right',
        margin: '30px 0px',
        paddingRight: '10px',
      }"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="changepage"
      hide-on-single-page
    >
    </el-pagination>
  </div>
</template>

<script>
import evaluate from "./datailFourEvaluate";
export default {
  // 保存当前选中规格id
  inject: ["activeSizeid"],
  props: ["messageList"],
  components: { evaluate },
  data() {
    return {
      // 是否只看当规格评价
      checked: false,
      // 推荐排序列表显示隐藏
      orderbyShow: false,
      // 当前选中tabbar
      active: 0,
      // 评论页面数据
      total: 0,
      pageSize: 10,
      pageNum: 1,
      // 评论列表详情
      EvaluateList: [],
    };
  },
  methods: {
    // 获取评论
    async getEvaluate() {
      const res = await this.$http({
        method: "get",
        url: "front/productEvaluate/getList",
        params: {
          productId: this.messageList.id,
          productParamId: this.checked ? this.activeSizeid() : "",
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        },
      });
      if (res.data.status == 200) {
        this.total = res.data.data.total;
        this.EvaluateList = res.data.data.list;
        console.log(this.EvaluateList, "评价详情");
      }
    },
    // 分页点击
    changepage(pageNum) {
      this.pageNum = pageNum;
      this.getEvaluate();
    },
  },
  created() {
    this.getEvaluate();
  },
};
</script>
<style lang="scss" scoped>
* {
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
    "\u5b8b\u4f53", sans-serif;
  color: #666;
}
.detailFour {
  .header {
    padding: 10px;
    background-color: #f7f7f7;
    border: 1px solid #eee;
    margin: 20px 0px;
    span {
      font: 700 14px "microsoft yahei";
    }
  }
  // tabbar选项列表
  .tabbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #666;
    border: 1px solid #f5f5f5;
    background-color: #fafafa;
    padding-left: 10px;
    & > div:nth-child(1) {
      display: flex;
      span {
        cursor: pointer;
        padding: 5px 10px;
      }
    }
    & > div:nth-child(2) {
      position: absolute;
      top: 0px;
      right: 6px;
      width: 105px;
      border: 1px solid rgba(0, 0, 0, 0);
      & > div {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        padding: 5px 10px;

        i {
          transform: scale(1.5);
          color: #999;
        }
      }
      .orderby {
        position: absolute;
        top: 0px;
        right: 6px;
        background: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 0 2px 0 #888;
        & > span:not(:nth-child(1)) {
          padding: 10px 0px 0px 0px;
          cursor: pointer;
        }
      }
    }
  }
  // 评价详情
  .detail {
    border-bottom: 1px solid #ddd;
  }
}
</style>