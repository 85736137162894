<template>
  <div class="detailTwo">
    <div class="box">
      <div>
        <div>基本参数</div>
        <div>
          <p>
            <span>产品编号</span>
            <span>{{ messageList.productModel.productNum }}</span>
          </p>
          <p v-if="messageList.productModel">
            <span>品牌</span>
            <span>{{ messageList.productModel.productBrand }}</span>
          </p>
        </div>
      </div>
      <div>
        <div>功能参数</div>
        <div>
          <p>
            <span>产品功能</span>
            <span>{{ messageList.productModel.productFunction }}</span>
          </p>
        </div>
      </div>
      <div>
        <div>
          <span>包装清单</span>
          <span>{{ messageList.productModel.packingList }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["messageList"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
* {
  font-weight: 400;
  font-size: 12px;
  color: #999;
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
    "\u5b8b\u4f53", sans-serif;
}
.box {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    margin: 20px;
    border-bottom: 1px solid #eee;
    & > div:nth-child(1) {
      width: 85px;
      text-align: right;
    }
    & > div:nth-child(2) {
      width: 500px;
      display: flex;
      flex-direction: column;
      & > p {
        margin-bottom: 20px;
        span:nth-child(1) {
          display: inline-block;
          width: 160px;
          text-align: right;
        }
        span:nth-child(2) {
          margin-left: 45px;
        }
      }
    }
  }
  & > div:nth-child(3) {
    border: none;
    div {
      width: 100%;
      text-align: left;
      span:nth-child(1) {
        display: inline-block;
        margin-left: 60px;
      }
      span:nth-child(2) {
        margin-left: 30px;
      }
    }
  }
}
</style>