<template>
  <div class="message">
    <my-header :position="'relative'"></my-header>
    <div class="box-header">
      <div>
        <span @click="$router.push('/index')">首页</span>
        <i class="el-icon-arrow-right"></i>
        <span>{{ messageList.companyName }}</span>
        <i class="el-icon-arrow-right"></i>
        <span @click="gocompany">{{ messageList.name }}</span>
      </div>
      <div>
        <span @click="gocompany">{{ messageList.companyName }}</span>
        <span @click="gochatRoom">
          <span
            :style="{ color: '#7da9fb' }"
            class="el-icon-chat-dot-round iconfont"
          ></span>
          <span>联系客服</span>
        </span>
        <span @click="gocompany">
          <span class="iconfont">&#xe64a;</span>
          <span>进入店铺</span>
        </span>
      </div>
    </div>
    <div>
      <div class="box">
        <good-message-center
          :messageList="messageList"
          @getshopnum="getshopnum"
          @changeSize="changeSize"
          @addShop="addShop"
        ></good-message-center>
        <div class="center">
          <good-message-store :messageList="messageList"></good-message-store>
          <good-message-detail
            @addShop="addShop"
            :messageList="messageList"
          ></good-message-detail>
        </div>
      </div>
      <!-- <div class="box">
        <good-message-center
          :messageList="messageList"
          @getshopnums="getshopnums"
          @changeSize="changeSize"
          @addShoppingList="addShoppingList"
        ></good-message-center>
        <div class="center">
          <good-message-store :messageList="messageList"></good-message-store>
          <good-message-detail
            @addShoppingList="addShoppingList"
            :messageList="messageList"
          ></good-message-detail>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import GoodMessageCenter from "../components/goodMessage/center";
import GoodMessageStore from "../components/goodMessage/store";
import GoodMessageDetail from "../components/goodMessage/datails/datail";
export default {
  components: { GoodMessageCenter, GoodMessageStore, GoodMessageDetail },
  data() {
    return {
      // 用户信息
      messageList: "",
      // 当前选中的规格
      activeSize: 0,
      // 购买数量
      num: 1,
      checked: sessionStorage.getItem("checked"),
    };
  },
  provide() {
    return {
      activeSizeid: this.getActiveSizeid,
    };
  },
  methods: {
    getRouteData() {
      console.log(JSON.parse(this.$route.query.name));
      this.messageList = JSON.parse(this.$route.query.name);
    },
    getActiveSizeid() {
      return this.messageList.productParamVos[this.activeSize].id;
    }, // 去往公司详情页
    // 进入商家详情
    gocompany() {
      this.$router.push({
        path: "/company",
        query: {
          id: this.messageList.companyId,
        },
      });
    },

    // 联系客服
    gochatRoom() {
      this.$router.push({
        path: "/chatRoom",
        query: {
          id: this.messageList.companyUserId,
        },
      });
    },
    // 添加到购物车
    async addShop() {
      const res = await this.$http({
        method: "post",
        url: "front/cart/add?type=1",
        data: {
          productId: this.messageList.id,
          productParamId: this.messageList.productParamVos[this.activeSize].id,
          count: this.num,
        },
      });
      if (res.data.status == 200) {
        this.$message({
          message: "添加购物车成功",
          type: "success",
        });
        this.$emit("getshopnum");
      } else {
        this.$message.error("添加购物车失败");
      }
    },
     // 添加到购物清单
    async addShoppingList() {
      const res = await this.$http({
        method: "post",
        url: "front/cart/addNote?type=2",
        data: {
          productId: this.messageList.id,
          productParamId: this.messageList.productParamVos[this.activeSize].id,
          count: this.num,
        },
      });
      
      if (res.data.status == 200) {
        this.$message({
          message: "添加购物清单成功",
          type: "success",
        });
        this.$emit("getshopnums");
      } else {
        this.$message.error("添加购物清单失败");
      }
    },
    // 修改选中规格和购买数量
    changeSize(activeSize, num) {
      this.activeSize = activeSize;
      this.num = num;
      console.log(this.activeSize, this.num);
    },
    // 获取购物车信息
    async getshopnum() {
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList?type=1",
        
      });

      if (res.data.data) {
        this.$store.commit(
          "altershopnum",
          res.data.data.cartProductVoList.length
        );
      }
    },
    // 获取购物清单信息
    async getshopnums() {
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList?type=2",
       
      });

      if (res.data.data) {
        this.$store.commit(
          "altershopnum",
          res.data.data.cartProductVoList.length
        );
      }
    },
  },
  created() {
    this.getRouteData();
    this.getshopnum();
    this.getshopnums();

  },
};
</script>
<style lang="scss" scoped>
* {
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
    "\u5b8b\u4f53", sans-serif;
}
.box-header {
  height: 45px;
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding: 0px 170px;
  color: #666;
  align-items: center;
  & > div:nth-child(1) {
    i {
      margin: 0 5px;
    }
  }
  & > div:nth-child(2) {
    .iconfont {
      margin-left: 10px;
      font-size: 18px;
      color: #e3393c;
      position: relative;
      top: 3px;
    }
    span {
      cursor: pointer;
    }
  }
}
.message {
  .box {
    margin: 0 auto;
    width: 80%;
    min-width: 1200px;
    .center {
      display: flex;
      & > div:nth-child(1) {
        min-width: 216px;
        flex: 4;
      }
      & > div:nth-child(2) {
        flex: 18;
        margin-left: 10px;
      }
    }
  }
}
</style>