<template>
  <div>
    <div class="box-box">
      <!-- 左侧部分 -->
      <div>
        <div>
          <img :src="messageList.mainImage" alt="" />
        </div>
        <!-- 底部点击切换图片 -->
        <div>
          <i class="el-icon-d-arrow-left" @click="step(-1)"></i>
          <div>
            <div
              v-for="(v, k) in 6"
              :key="k"
              :style="{
                border: activeImg == k ? '3px solid #e53e41' : '',
              }"
              :class="{ back: back, next: next }"
              @mouseenter="activeImg = k"
            >
              <img :src="messageList.mainImage" />
            </div>
          </div>
          <i class="el-icon-d-arrow-right" @click="step(1)"></i>
        </div>
        <div>
          <span>
            <i class="iconfont">&#xe635;</i>
            关注
          </span>
          <span>
            <i class="iconfont">&#xe662;</i>
            分享
          </span>
          <span>
            <i class="iconfont">&#xe674;</i>
            对比
          </span>
        </div>
      </div>
      <!-- 右侧部分 -->
      <div>
        <div class="top">
          <div class="title">
            {{ messageList.name }}
          </div>
          <div :style="{ color: 'red', fontSize: '12px' }">
            【元旦盛典，iPhone12系列预售开启】iPhone11焕新包装，电源适配器及EarPods耳机需单独购买。点击抢购！查看>
          </div>
          <div class="price">
            <div>
              <span>厨盟价</span>
              <span>{{
                `￥${messageList.productParamVos[activeSize].price.toFixed(2)}`
              }}</span>
            </div>
          </div>
          <div class="promotion">
            <span>优惠券</span>
            <span>
              <span>满5000减300</span>
            </span>
            <span>
              <span> 满3500减200 </span>
            </span>
          </div>
          <div class="site">
            <span>配送至</span>
            <div>
              <span>{{ address }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <span> 有货 </span>
          </div>
          <div class="consignment">
            <span>由</span>
            <span>{{ messageList.companyName }}</span>
            <span>负责发货, 并提供售后服务.</span>
          </div>
        </div>
        <div class="center">
          <div class="size">
            <div>选择规格</div>
            <div>
              <div
                v-for="(v, k) of messageList.productParamVos"
                :key="k"
                :style="{ border: activeSize == k ? '1px solid red' : '' }"
                @click="activeSize = k"
              >
                <img :src="messageList.mainImage" alt="" />
                <span v-if="v.size">
                  <span>型号:</span>
                  <span>{{ v.size }}</span>
                </span>
                <span v-if="v.steelModel">
                  <span>不锈钢型号:</span>
                  <span>{{ v.steelModel }}</span>
                </span>
                <span v-if="v.thickness">
                  <span>厚度:</span>
                  <span>{{ v.thickness }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div>
            <el-input-number
              v-model="num"
              controls-position="right"
              :min="1"
            ></el-input-number>
            <span @click="addShop">加入购物车</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
            <span @click="addShoppingList">加入采购清单</span>
          </div>
          <div>
            <span>温馨提示</span>
            <span>·不支持7天无理由退货</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  props: ["messageList"],
  data() {
    return {
      // 当前选中的规格
      activeSize: 0,
      // 购买数量
      num: 1,
      // 地址
      address: JSON.parse(sessionStorage.getItem("address")),
      activeImg: 0,
      back: false,
      next: false,
    };
  },
  watch: {
    activeSize() {
      this.$emit("changeSize", this.activeSize, this.num);
    },
    num() {
      this.$emit("changeSize", this.activeSize, this.num);
    },
  },
  methods: {
    // 添加到购物车
    addShop() {
      this.$emit("addShop");
    },
     // 添加到购物清单
    async addShoppingList() {
      const res = await this.$http({
        method: "post",
        url: "front/cart/addNote",
        data: {
          productId: this.messageList.id,
          productParamId: this.messageList.productParamVos[this.activeSize].id,
          count: this.num,
        },
      });
      if (res.data.status == 200) {
        this.$message({
          message: "添加购物清单成功",
          type: "success",
        });
        this.$emit("getshopnum");
      } else {
        this.$message.error("添加购物清单失败");
      }
    },
    //   点击切换图片
    step(num) {
      if (num == 1 && this.activeImg != 4) {
        if (this.activeImg > 2) {
          this.next = true;
        }
        setTimeout(() => {
          this.next = false;
        }, 50);

        this.activeImg = this.activeImg + num;
      } else if (num == -1 && this.activeImg != 0) {
        if (this.activeImg < 3) {
          this.back = true;
        }
        setTimeout(() => {
          this.back = false;
        }, 50);
        this.activeImg = this.activeImg + num;
      } else {
      }
    },
  },
  created() {
    console.log(this.messageList);
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
    "\u5b8b\u4f53", sans-serif;
}
$width: 33%;
.back {
  margin: 0 auto;
  transition: all 0.3s linear;
}
.box-box {
  display: flex;
  margin-bottom: 20px;
  // 左侧部分
  & > div:nth-child(1) {
    padding: 10px 10px;
    flex: 12;
    display: flex;
    flex-direction: column;
    width: 500px;
    & > div:nth-child(1) {
      & > img {
        display: block;
        margin: 0 auto;
        border: 1px solid #eee;
        width: 450px;
        min-height: 300px;
        max-height: 450px;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      width: 450px;
      height: 80px;
      align-items: center;
      margin: 10px auto;
      text-align: center;
      i {
        flex: 1;
        font-size: 30px;
        color: #999;
        font-weight: 900;
      }
      & > div {
        flex: 10;
        overflow-x: hidden;
        z-index: 1000;
        display: flex;
        & > div {
          box-sizing: border-box;
          margin: 2px;
          min-width: 19%;
          height: 64px;
          border: 3px solid #fff;
          & > img {
            width: 100%;
            height: 58px;
          }
        }
      }
    }
    & > div:nth-child(3) {
      width: 450px;
      margin-left: 25px;
      span {
        font-size: 14px;
        cursor: pointer;
        margin: 0 2px;
        i {
          color: #e3393c;
          font-size: 16px;
        }
      }
    }
  }
  // 右侧部分
  & > div:nth-child(2) {
    flex: 18;
    display: flex;
    flex-direction: column;
    .top {
      border-bottom: 1px dotted #dfdfdf;
      .title {
        padding: 20px 0px 8px 10px;
        font: 700 16px Arial, "microsoft yahei";
        color: #666;
        line-height: 28px;
      }
      .price {
        margin-top: 10px;
        background: #f3f3f3;
        padding: 6px 0px 3px 0px;
        & > div {
          span:nth-child(1) {
            letter-spacing: 8px;
            font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
              "\u5b8b\u4f53", sans-serif;
            line-height: 22px;
            padding-left: 10px;
            font-family: simsun;
            color: #999;
          }
          span:nth-child(2) {
            margin-left: 10px;
            font-size: 22px;
            color: #e4393c;
            font-family: "microsoft yahei";
          }
        }
      }
      .promotion {
        background: url(//static.360buyimg.com/item/unite/1.0.114/components/default-soa/common/i/price-bg.png)
          0 -12px repeat-x #efefef;
        padding: 6px 0px 10px 0px;
        & > span:nth-child(1) {
          letter-spacing: 8px;
          font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
            "\u5b8b\u4f53", sans-serif;
          line-height: 22px;
          padding-left: 10px;
          font-family: simsun;
          color: #999;
        }
        span:nth-child(2),
        span:nth-child(3) {
          position: relative;
          text-align: center;
          display: inline-block;
          line-height: 15px;
          span {
            padding: 0 10px;
            color: #df3033;
            font-size: 12px;
            line-height: 13px;
          }
          height: 16px;
          padding-left: 2px;
          border-top: 1px solid #df3033;
          border-bottom: 1px solid #df3033;
          background: #ffdedf;
          white-space: nowrap;
          margin-left: 13px;
          cursor: pointer;
          &::before {
            display: inline-block;
            content: "";
            left: 0;
            background-position: -2px 0;
            position: absolute;
            top: -1px;
            display: block;
            height: 18px;
            width: 2px;
            overflow: hidden;
            background: url(//static.360buyimg.com/item/unite/1.0.114/components/default-soa/prom/i/quan-arr.gif)
              no-repeat;
          }
          &::after {
            display: inline-block;
            content: "";
            right: 0;
            background-position: -2px 0;
            position: absolute;
            top: -1px;
            display: block;
            height: 18px;
            width: 2px;
            overflow: hidden;
            background: url(//static.360buyimg.com/item/unite/1.0.114/components/default-soa/prom/i/quan-arr.gif)
              no-repeat;
          }
        }
      }
      .site {
        display: flex;
        align-items: center;
        margin-top: 6px;
        & > span:nth-child(1) {
          letter-spacing: 8px;
          font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
            "\u5b8b\u4f53", sans-serif;
          line-height: 22px;
          padding-left: 10px;
          font-family: simsun;
          color: #999;
        }
        & > div:nth-child(2) {
          position: relative;
          margin-left: 5px;
          display: inline-block;
          border: 1px solid #cecbce;
          color: #666;
          padding: 0 28px 0 4px;
          line-height: 23px;
          overflow: hidden;
          span {
            display: inline-block;
            color: #666;
          }
          i {
            position: absolute;
            top: -3px;
            right: 5px;
            color: #999;
            font-size: 20px;
          }
        }
        & > span:nth-child(3) {
          margin: 0px 5px;
          color: #999;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .consignment {
        margin-left: 80px;
        color: #999;
        line-height: 38px;
        & > span:nth-child(2) {
          color: #e4393c;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
    .center {
      border-bottom: 1px dotted #dfdfdf;
      .size {
        display: flex;
        margin-top: 13px;
        & > div:nth-child(1) {
          width: 70px;
          letter-spacing: 3px;
          font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
            "\u5b8b\u4f53", sans-serif;
          line-height: 22px;
          padding-left: 10px;
          font-family: simsun;
          color: #999;
          margin-top: 10px;
        }
        & > div:nth-child(2) {
          width: 90%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          & > div {
            &:hover {
              border: 1px solid #e3393c;
            }
            display: flex;
            align-items: center;
            height: 40px;
            background-color: #f7f7f7;
            margin-right: 7px;
            margin-bottom: 4px;
            border: 1px solid #ccc;
            color: #666;
            cursor: pointer;
            line-height: 40px;
            img {
              height: 40px;
              width: 40px;
            }
            & > span {
              display: flex;
              flex-wrap: nowrap;
              display: inline-block;
              height: 20px;
              margin: 0 8px;
            }
          }
        }
      }
    }
    .bottom {
      margin-top: 10px;
      margin-left: 10px;
      & > div:nth-child(1) {
        & ::v-deep .el-input__inner {
          border-radius: 0px;
          padding: 0;
          width: 60px;
          border: none;
          height: 46px;
        }
        & ::v-deep .el-input-number {
          width: 80px;
          height: 46px;
          border: 1px solid #ccc;
        }
        & ::v-deep .el-input-number__decrease,
        & ::v-deep .el-input-number__increase {
          width: 20px;
        }
        span {
          position: relative;
          top: 2px;
          left: 10px;
          display: inline-block;
          height: 48px;
          line-height: 48px;
          padding: 0 26px;
          font-size: 18px;
          font-family: "microsoft yahei";
          font-weight: 700;
          background-color: #df3033;
          color: #fff;
          cursor: pointer;
        }
      }
      & > div:nth-child(2) {
        margin-top: 20px;
        span {
          padding-left: 10px;
          font-family: simsun;
          color: #999;
        }
      }
    }
  }
}
</style>